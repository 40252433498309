:root {
  --main-bg-color: #f5f5f5;
  --bg-color-1: #ebefe7;
  --bg-color-1-shadow: #f5f7f3;
  --accent-bold-1: #5ca4a9;
  --accent-bold-2: #ed6a5a;
  --accent-light-1: #9bc1bc;
  --accent-light-2: #f4f1bb;
  --accent-light-3: #f39b91;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  font-weight: bold;
  color: var(--accent-bold-1);
}
